import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "index" */ '../views/index/Login.vue'),
    meta: {
      metaInfo: {
        title: "枫华嘉美 - 您身边的跨境电商物流优质服务商！",
        keywords: "枫华嘉美,欧美FBA,美国海运卡派",
        description: "枫华嘉美，二十年沉淀，品质如一，您身边的跨境电商物流优质服务商。枫华嘉美依托华运国际物流二十年平台经验，为您提供专业的欧美FBA头程空海派运输、海外仓等服务。枫华嘉美优势渠道：美国海运卡派，以星快船，美森快船，盐田海派，美国空派。"

      }
    }
  },
  {
    path: '/',
    name: 'navbar',
    component: () => import(/* webpackChunkName: "navbar" */ '../components/navbar/NavBar.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/Index.vue'),
        meta: {
          metaInfo: {
            title: "枫华嘉美 - 您身边的跨境电商物流优质服务商！",
            keywords: "枫华嘉美,欧美FBA,美国海运卡派",
            description: "枫华嘉美，二十年沉淀，品质如一，您身边的跨境电商物流优质服务商。枫华嘉美依托华运国际物流二十年平台经验，为您提供专业的欧美FBA头程空海派运输、海外仓等服务。枫华嘉美优势渠道：美国海运卡派，以星快船，美森快船，盐田海派，美国空派。"

          }
        }
      },

      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
        meta: {
          metaInfo: {
            title: "枫华嘉美 - 您身边的跨境电商物流优质服务商！",
            keywords: "枫华嘉美,欧美FBA,美国海运卡派",
            description: "枫华嘉美，二十年沉淀，品质如一，您身边的跨境电商物流优质服务商。枫华嘉美依托华运国际物流二十年平台经验，为您提供专业的欧美FBA头程空海派运输、海外仓等服务。枫华嘉美优势渠道：美国海运卡派，以星快船，美森快船，盐田海派，美国空派。"

          }
        }
      },
      {
        path: '/recruit',
        name: 'recruit',
        component: () => import(/* webpackChunkName: "recruit" */ '../views/recruit/Recruit.vue'),
        meta: {
          metaInfo: {
            title: "枫华嘉美 - 您身边的跨境电商物流优质服务商！",
            keywords: "枫华嘉美,欧美FBA,美国海运卡派",
            description: "枫华嘉美，二十年沉淀，品质如一，您身边的跨境电商物流优质服务商。枫华嘉美依托华运国际物流二十年平台经验，为您提供专业的欧美FBA头程空海派运输、海外仓等服务。枫华嘉美优势渠道：美国海运卡派，以星快船，美森快船，盐田海派，美国空派。"

          }
        }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
