import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user/user"
import getters from "./getters"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    metaInfo: {
      title: "枫华嘉美 - 您身边的跨境电商物流优质服务商！",
      keywords: "枫华嘉美,欧美FBA,美国海运卡派",
      description: "枫华嘉美，二十年沉淀，品质如一，您身边的跨境电商物流优质服务商。枫华嘉美依托华运国际物流二十年平台经验，为您提供专业的欧美FBA头程空海派运输、海外仓等服务。枫华嘉美优势渠道：美国海运卡派，以星快船，美森快船，盐田海派，美国空派。"
    }
  },
  mutations: {
    CAHNGE_META_INFO(state, metaInfo) {
      state.metaInfo = metaInfo;
    }
  },
  actions: {
  },
  modules: {
    user
  },
  getters
})
