const baseApi = {
    api: process.env.VUE_APP_FBA_API,
    products_api: process.env.VUE_APP_FBA_WEB_API,
    sofreight_api: process.env.VUE_APP_SF_HOMEROOT,
    sofreight_oapi: process.env.VUE_APP_SF_API
}

const baseUrl = window.origin;

if(baseUrl.indexOf(process.env.VUE_APP_SF_BASE) < 0){
    console.log(process.env.VUE_APP_SF_BASE,'process.env.VUE_APP_SF_BASE')
    baseApi.sofreight_api  = '//192.168.66.206/sf4/homeroot';
    baseApi.sofreight_oapi = '//192.168.66.206/sf4/apiroot';
}

export default baseApi;