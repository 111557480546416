import axios from "@/utils/http"
import baseApi from "./base"
import qs from 'qs';

// 拉取热门港口列表
export function getHotPorts(data) {
    axios.httpfn(0,'post')
    return axios.post(`${axios.http() + baseApi.api}/ports/pol_hot`, qs.stringify(data))
}

// 搜索港口
export function getPort(data) {
    axios.httpfn(0,'post')
    return axios.post(`${axios.http() + baseApi.api}/ports/port_search`, qs.stringify(data))
}

// 拉取fba仓库列表
export function getFbaWh() {
    axios.httpfn(0,'get')
    return axios.get(`${axios.http()}//api.sofreight.com/BaseData/fbaBaseData`)
}

export function getGeetest () {
    axios.httpfn(1,'get')
    let URL = `${axios.http() + baseApi.sofreight_api}/Register/imgVerify?t=` + (new Date()).getTime();
    return axios.get(URL,{withCredentials:true})
}

export function loginApi(data) {
    axios.httpfn(1,'get')
    let URL = `${ axios.http() + baseApi.sofreight_api}/DMember/getMemberInfo?token=${data.token}`;
    return axios.get(URL,{withCredentials:true})
}

export function saveOrderApi(data) {
    axios.httpfn(1,'post')
    return axios.post(`${ axios.http() + baseApi.sofreight_api}/fbaBooking/oneKeyBooking`, qs.stringify(data),{withCredentials:true})
}

export function mobileApi(data) {
    axios.httpfn(1,'post')
    return axios.post(`${axios.http() + baseApi.sofreight_api}/SeaFclBooking/mobileVerify.html`, qs.stringify(data),{withCredentials:true})
}


export function logoutApi(data) {
    axios.httpfn(1,'get')
    return axios.get(`${axios.http() + baseApi.sofreight_api}/Login/logout.html?token=${data.token}`,{withCredentials:true})
}

/*export function nextApi(data) {
    return axios.post(`//www.nexttoship.com/backend/public/api/Product/ProductList`, qs.stringify(data))
}*/


