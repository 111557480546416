import store from './store'
import router from './router'
router.beforeEach((to, from, next) => {
    let fromUrl  = window.origin+'/login';
    console.log(fromUrl,'fromUrlfromUrl');
    let token = localStorage.getItem("token") || '';
    store.dispatch("getUserInfo",{token:token}).then(() => {
        let info = store.state.user.info;
        next()
    });
})