import Vue from 'vue'
import App from './App.vue'
import './permission'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.prototype.$axios = axios
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './utils/rem.js'
// import ElementUI from './utils/element.js'
import './assets/js/gt.js'

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'M5Uw4QpKkZ3X5zoZaqslS7hf3vUTSK8A'
})

Vue.use(ElementUI)
import Meta from 'vue-meta';
Vue.use(Meta);

//重置样式
import '@/assets/css/reset.css'
import { Toast } from 'wc-messagebox'
import 'wc-messagebox/style.css'
Vue.use(Toast)

import {
  Pagination,
  Select,
  Option,
  Input,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Popover,
  Row,
  Col,
  Tooltip,
  Tabs,
  TabPane,
  Tag,
} from 'element-ui';
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Popover)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tooltip)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo)
    store.commit("CAHNGE_META_INFO", to.meta.metaInfo)
  next()
});

new Vue({
  router,
  store,
  render: h => h(App),

  data() {
    return {
      isApp: false,
      appTime: "white",
      sfapiUrl: '',
      clientKey: '',
      webUrl: '',
    }
  },
  metaInfo() {
    return {
      title: this.$store.state.metaInfo.title,
      meta: [
        {
          name: "keywords",
          content: this.$store.state.metaInfo.keywords
        }, {
          name: "description",
          content: this.$store.state.metaInfo.description
        }
      ]
    }
  },

  created() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    this.isApp = flag ? 1 : 0;
    /*  根据host判断api地址 */
    if (location.href.indexOf('lclcn.sofreight.com') != -1 || location.href.indexOf('sofreight_app.yemet.com') != -1) {

      this.sfapiUrl = '//sofreight-app.yemet.com/api/';
      this.clientKey = '77add88579607064'; // 进入头条的clientKey
      this.webUrl = '//www.sofreight.com/';
    } else if (location.href.indexOf('192') != -1) {
      this.sfapiUrl = '//192.168.6.220:8085/api/';
      this.clientKey = 'c33758d309702c62';
      this.webUrl = '//192.168.6.206/sf4/homeroot/';
    } else if (location.href.indexOf('localhost') !== -1) {
      this.sfapiUrl = 'http://news.sofreight/api/';//172.16.0.10:8085/api/';
      this.clientKey = 'c33758d309702c62';
      this.webUrl = '//192.168.6.206/sf4/homeroot/';
    } else {
      this.sfapiUrl = '//172.16.0.10:8085/api/';
      this.clientKey = 'c33758d309702c62';
      this.webUrl = '//192.168.6.206/sf4/homeroot/'
    }

  },
}).$mount('#app')
