/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios"
import qs from "qs";

// 创建axios实例
var instance = axios.create({ timeout: 1000 * 20 });

// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';

/**
 * 请求拦截器 
 * 每次请求前，如果存在token则在请求头中携带token 
 */
instance.interceptors.request.use(
    config => {
        // 处理axios两种请求方式            
        if (config.method === "post") {
            let data = qs.parse(config.data)
            config.data = qs.stringify({
                ...data
            })
        } else if (config.method === "get") {
            config.params = {
                ...config.params
            }
        }
        return config;
    },
    error => {
        Promise.error(error)
    })

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res),
    // 请求失败
    error => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围 
            // errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            // 处理断网的情况
        }
    });


instance.httpfn = function (type,method){
    switch (method){
        case 'post':
            if(type ==1){
                instance.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
                instance.defaults.headers.post['withCredentials'] = true;
            }else{
                delete  instance.defaults.headers.post['X-Requested-With'];
                delete  instance.defaults.headers.post['withCredentials'];
            }
            break;
        case 'get':
            if(type == 1){
                instance.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
                instance.defaults.headers.get['withCredentials'] = true;
            }else{
                delete  instance.defaults.headers.get['X-Requested-With'];
                delete  instance.defaults.headers.get['withCredentials'];
            }
            break;
    }
}

instance.http = function (){
    let http = 'http:'
    let Url  = window.origin;
    if(Url.indexOf(process.env.VUE_APP_SF_BASE) > -1){
        http = 'https:'
    }
    return http;
}

export default instance