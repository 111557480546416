import Cookies from 'js-cookie'
import {
    SET_TOKEN,
    SET_USERINFO,
} from "./mutation-types"
import { loginApi,logoutApi  } from "@/service/index";

const user = {
    state: {
        info: {},
    },
    mutations: {
        [SET_USERINFO]: (state, info) => {
            state.info = info
        },
    },
    actions: {
            getUserInfo({ commit }, data) {
            return loginApi(data).then(res => {
                /*res = {
                    status : 1,
                    data:{
                        accounts_id:null,
                        fullname:null,
                        mid:123,
                        user_name:'1520036297'
                    }
                }*/
                console.log(res,'getUserInfogetUserInfogetUserInfo')
                const list = res;
                let token = data.token || '';
                localStorage.setItem("token", token);
                if(list.status == 1) {
                   let userinfo = list.data;
                    commit("SET_USERINFO", userinfo)
                }
            });
        },

        logoutApiFn({ commit }, data) {
            return logoutApi(data).then(res => {
                const list = res;
                localStorage.setItem("token", '');
            });
        }
    }
}

export default user